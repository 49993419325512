<template>
  <div class="amendments-container" v-if="idCards">
    <h1 style="margin-left: 20px;">{{$t('Ausweise (genehmigen)')}}</h1>
    <div class="idcard-con">
      <div class="con mt-4">
        <vs-input class="search-input org-width" icon-after="search" :placeholder="$t('dashboard.expire-card.table.name-search')" v-model="search" icon="search" icon-no-border label-placeholder="icon-no-border" />
        <div class="outer-con2">
          <div class="table-container" style="overflow-y: auto; max-width: 100%;">
            <table>
              <thead>
                <tr>
                  <th @click="sort('created_at')">{{$t("Ausweis vom")}}<div class="arrow" v-if="'created_at' == currentSort" v-bind:class="ascending ? 'arrow_up' : 'arrow_down'"></div></th>
                  <th @click="sort('organisation_name')">{{$t("dashboard.expire-card.table.organization")}}<div class="arrow" v-if="'organisation_name' == currentSort" v-bind:class="ascending ? 'arrow_up' : 'arrow_down'"></div></th>
                  <th @click="sort('person_last_name')">{{$t("Name")}}<div class="arrow" v-if="'person_last_name' == currentSort" v-bind:class="ascending ? 'arrow_up' : 'arrow_down'"></div></th>
                </tr>
              </thead>
              <tbody>
                <tr class="cursor" v-for="idCard in idCards" :key="idCard.id" @click="openIdCard(idCard)">
                  <td>{{ idCard.created_at | moment("DD.MM.YYYY") }}</td>
                  <td>{{idCard.organisation_name}}</td>
                  <td>{{idCard.person_last_name}}, {{idCard.person_first_name}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <PopupIDcard ref="popup" :organization="selectedOrganization" />

    <!-- DEBUG MODE -->
    <div v-if="isDebugMode">

      <h1>idCards</h1>
      {{idCards}}

      <h1>user</h1>
      {{user}}
    </div>
    
  </div>
</template>

<script>
  import Vue from "vue";
  import PopupIDcard from "@/components/id-card/PopupIDcard.vue";

  export default Vue.extend({
    name: "UnapprovedIdCards",
    components: {
      PopupIDcard,
    },

    async mounted() {
      await this.$store.dispatch("idCard/loadIdCards", { status: "open"} );
      await this.$store.dispatch("customer/loadCustomer", this.user.customer_id);
    },

    data() {
      return {
        selectedOrganization: {},
        search: "",
        ascending: false,
        currentSort:'created_at',
        currentSortDir:'asc'
      };
    },
    computed: {
      idCards() {
        const idCards = this.$store.getters["idCard/getIdCards"];
        idCards.sort((a,b)=> {
          let modifier=1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
        });
        return idCards.filter((card) => {
          if (this.search != "") {
            const name = card.person_first_name + " " + card.person_last_name;
            const searchMatch = name.toLowerCase().includes(this.search.toLowerCase());
            if (searchMatch) {
              return true;
            }
            return false;
          } else {
            return card.status == "open";
          }
        });
      },
      user() {
        return this.$store.getters["auth/getUser"];
      },
      customer() {
        return this.$store.getters["customer/loadCustomer"];
      },
      // getUnapprovedIdCards() {
      //   const idCards = this.$store.getters["idCard/getIdCards"];
      //   return idCards.filter((card) => {
      //     if (card.activated_at === null && this.search != "") {
      //       const name = card.person_first_name + " " + card.person_last_name;
      //       const searchMatch = name.toLowerCase().includes(this.search.toLowerCase());
      //       if (searchMatch) {
      //         return true;
      //       }
      //       return false;
      //     } else {
      //       return card.activated_at === null;
      //     }
      //   });
      // },
    },

    methods: {
      sort:function(s) {
        if(s === this.currentSort) {
          this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
          this.ascending = !this.ascending;
        }
        this.currentSort = s;
      },
      async openIdCard(idCard) {
        const popup = this.$refs.popup;
        popup.openEdit(idCard);
      },
    },
  });
</script>
<style></style>
